.homepage2 {
  height: 100%;
}
@media only screen and (max-width: 768px) {
  .homepage2 {
    height: auto;
  }
}

.section {
  /* height: 600px; */
  box-shadow: 0px 0px 50px 0px rgb(35 31 32 / 10%);
}

@media only screen and (max-width: 1024px) {
  .section {
    height: auto;
  }
}

.horizontalBorder {
  height: 150px;
  width: 2px;
  left: 20px;
  top: 20px;
  position: absolute;
}
.verticalBorder {
  width: 150px;
  height: 2px;
  left: 10px;
  top: 30px;
  position: absolute;
}

.imageScale {
    animation: imageAnimation ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
  
  @keyframes imageAnimation {
    0% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
  
