.navbarOpen {
  animation: navBarOpenAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes navBarOpenAnimation {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.navbarClose {
  animation: navBarCloseAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes navBarCloseAnimation {
  0% {
    width: 100%;
  }
  100% {
    width: 0;
  }
}

.textAnimationOpen {
  animation: textAnimationOpen ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes textAnimationOpen {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.textAnimationClose {
    animation: textAnimationClose ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
  @keyframes textAnimationOpen {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
