.nav-tabs .nav-link.active {
    color: #f8c784 !important;
    border-color: #f8c784 !important;
  }
  
  .nav-tabs .nav-link.active:hover {
    color: white !important;
  }
  
  .nav-tabs .nav-link {
    font-size: 16px;
  }
  
  .nav-tabs .nav-link:hover {
    color: white !important;
  }
  
  
  @media only screen and (max-width: 767px) {
    .container {
      flex-direction: column;
      width: 100%;
      align-items: center;
      perspective: 1000px;
    }
  }
  
  .container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    transform-style: preserve-3d;
    perspective: 500px;
    margin: auto;
  }
  .container .box {
    position: relative;
    width: 350px;
    height: 400px;
    border-radius: 10px;
    background: #000;
    transition: 0.5s;
    transform-style: preserve-3d;
    overflow: hidden;
    margin-right: 25px;
    margin-top: 45px;
  }
  @media only screen and (max-width: 767px) {
    .container .box{
      flex-direction: column;
      width: 70%;
      align-items: center;
      perspective: 1000px;
    }
  }
  
  
  /* .container:hover .box {
    transform: rotateY(1deg);
  }
  .container .box:hover ~ .box {
    transform: rotateY(-1deg);
  } */
  .container .box:hover {
    transform: rotateY(0deg) scale(1.2);
    z-index: 1;
    box-shadow: 0 25px 40px rgba(0, 0, 0, 0.5);
  }
  .container .box .imgBx {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .container .box .imgBx:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #f8c784, #000);
    z-index: 1;
    opacity: 0;
    transition: 0.5s;
    mix-blend-mode: multiply;
  }
  .container .box:hover .imgBx:before {
    opacity: 1;
  }
  .container .box .imgBx img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .container .box .content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    /* padding: 20px; */
    align-items: flex-end;
    box-sizing: border-box;
    width: 100%;
  }
  .container .box .content h2 {
    transition: 0.5s;
    text-transform: uppercase;
    margin-bottom: 15px;
    font-size: 20px;
    /* transform: translateY(100px); */
    transition-delay: 0.3s;
  }
  .container .box:hover .content h2 {
    transform: translateY(0px);
  }
  .container .box .content p {
    color: #fff;
    transition: 0.5s;
    font-size: 14px;
    /* transform: translateY(100px); */
    transition-delay: 0.4s;
  }
  .container .box:hover .content p {
    transform: translateY(0px);
  }
  
  .styles-module_wrapper__1I_qj {
    height: 400px !important;
    z-index: 9999 !important;
  }