.textwidth{
    width:500px;
}

@media only screen and (max-width: 1024px) {
    .textwidth {
      width:auto
    }
  }

.numberBox{
    box-shadow: 0px 0px 50px 0px rgb(35 31 32 / 10%);
}